<template>
  <div class="index">
    <navigation msg="岗位发布"></navigation>
    <div class="title">
      <div class="left">试试电脑端快速发布</div>
      <div class="right"><van-icon name="arrow" /></div>
    </div>
    <div class="step">
      <div class="step_1">5</div>
      <div class="step_xian"></div>
      <div class="step_2">6</div>
    </div>
    <div class="step_text">
      <div class="step_text_1">生成岗位报价</div>
      <div class="step_text_2">完成岗位发布</div>
    </div>
    <div class="item1">
      <div class="label">招聘岗位名称</div>
      <div class="name">
        <van-field v-model="forms.job_nickname" readonly placeholder="2500元" />
      </div>
    </div>
    <!-- <div class="item1">
      <div class="label">岗位建议薪资</div>
      <div class="name">
        <van-field v-model="value" placeholder="16500元" />
      </div>
    </div> -->
    <div class="item1">
      <div class="label">岗位生成时间</div>
      <div class="name">
        <van-field v-model="time" readonly placeholder="2022年09月06日" />
      </div>
    </div>

    <div style="height: 2rem"></div>
    <div class="footer">
      <div class="btn1" @click="goindex">返回岗位详情首页</div>
      <div class="btn2" @click="faifa(1)">预览/打印方案报告</div>
      <div class="btn3" @click="faifa(2)">下载报告</div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  data() {
    return {
      value: "",
      value2: "",
      checked: true,
      time: "",
    };
  },
  computed: {
    // 引入vuex中存储的数据
    ...mapState({
      forms: (state) => state.form,
    }),
  },
  methods: {
    faifa(type) {
      this.$router.push({
        path: "/projectreport",
        query: {
          xz: type,
        },
      });
    },
    goindex() {
      this.$router.push("/home");
    },
  },
  mounted() {
    this.time = new Date(+new Date() + 8 * 3600 * 1000)
      .toJSON()
      .substr(0, 10)
      .replace("T", " ");
  },
};
</script>

<style lang='scss' scoped>
.index {
  background-color: #fff;
  height: 100vh;
}
.van-cell {
  padding: 10px 0px;
}
.title {
  display: flex;
  justify-content: space-between;
  height: 0.88rem;
  padding: 0 0.28rem;
  line-height: 0.88rem;
  background: #fff0e5;
  .left,
  .right {
    font-size: 0.28rem;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ff3b24;
  }
}
.step {
  margin-top: 0.32rem;
  padding: 0 1.12rem;
  display: flex;
  align-items: center;
  .step_1,
  .step_2 {
    width: 0.42rem;
    height: 0.42rem;
    border-radius: 50%;
    background: #ff3b24;
    text-align: center;
    line-height: 0.42rem;
  }
  .step_xian {
    flex: 1;
    height: 8px;
    background: linear-gradient(270deg, #ffc4bd 0%, #ff3b24 100%);
  }
  .step_1 {
    transform: translateX(0.02rem);
    color: #fff;
  }
  .step_2 {
    transform: translateX(-0.02rem);
    color: #fff;
    font-size: 0.28rem;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    background: #ff3b24;
    // opacity: 0.3;
  }
}
.step_text {
  margin-bottom: 0.8rem;
  margin-top: 0.21rem;
  display: flex;
  justify-content: space-between;
  padding: 0 0.35rem;
  .step_text_1 {
    font-size: 0.28rem;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #222222;
  }
  .step_text_2 {
    font-size: 0.28rem;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #222222;
  }
}
.index /deep/ input::placeholder {
  font-size: 0.3rem;
}
//输入框
.item1 {
  margin: 0 0.2rem 0.57rem;
  border-bottom: 0.03rem solid #f2f2f2;
  .label {
    font-size: 0.28rem;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    span {
      margin-right: 0.1rem;
      color: #ff3b24;
    }
  }
  .name {
    display: flex;
    align-items: center;

    height: 1.12rem;
  }
}
//选择框
.item2 {
  margin: 0 0.2rem 0.2rem;
  border-bottom: 0.03rem solid #f2f2f2;
  .label {
    font-size: 0.28rem;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    span {
      margin-right: 0.1rem;
      color: #ff3b24;
    }
    i {
      font-style: none;
      color: #ff3b24;
    }
  }
  .name {
    height: 1.12rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    div {
      font-size: 0.3rem;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #c8c8c8;
    }
  }
  .solt {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.2rem;
    .btn1 {
      text-align: center;
      line-height: 0.71rem;
      box-sizing: border-box;
      width: 3.32rem;
      height: 0.71rem;
      background: #fff0e5;
      border-radius: 0.03rem;
      border: 0.02rem solid #ff3b24;
      font-size: 0.24rem;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ff3b24;
    }
    .btn2 {
      text-align: center;
      line-height: 0.71rem;
      box-sizing: border-box;
      width: 3.32rem;
      height: 0.71rem;
      background: #f1f0ef;
      font-size: 0.24rem;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #6d6b6b;
      border-radius: 0.03rem;
    }
  }
}
// 多选择框
.item3 {
  margin: 0 0.2rem 0.57rem;
  border-bottom: 0.03rem solid #f2f2f2;
  .label {
    font-size: 0.28rem;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    span {
      margin-right: 0.1rem;
      color: #ff3b24;
    }
    i {
      font-style: none;
      color: #6277da;
    }
  }
  .name {
    height: 1.12rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    > div {
      flex: 1;
      font-size: 0.3rem;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      display: flex;
      justify-content: space-between;
      .right {
        color: #c8c8c8;
        margin-right: 0.15rem;
      }
    }
  }
  .solt {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.2rem;
    .btn1 {
      text-align: center;
      line-height: 0.71rem;
      box-sizing: border-box;
      width: 3.32rem;
      height: 0.71rem;
      background: #fff0e5;
      border-radius: 0.03rem;
      border: 0.02rem solid #ff3b24;
      font-size: 0.24rem;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ff3b24;
    }
    .btn2 {
      text-align: center;
      line-height: 0.71rem;
      box-sizing: border-box;
      width: 3.32rem;
      height: 0.71rem;
      background: #f1f0ef;
      font-size: 0.24rem;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #6d6b6b;
      border-radius: 0.03rem;
    }
  }
}
.xy {
  margin-left: 0.2rem;
  margin-bottom: 0.2rem;
}
.footer {
  position: fixed;
  box-sizing: border-box;
  display: flex;
  // justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 0 0.29rem;
  left: 0;
  width: 100%;
  bottom: 0;
  height: 2.71rem;
  border-top: 0.02rem solid #f2f2f2;
  background: #ffffff;
  .btn1 {
    text-align: center;
    line-height: 0.95rem;
    width: 3.07rem;
    height: 0.95rem;
    background: #ffffff;
    border-radius: 0.1rem;
    border: 0.03rem solid #c3c3c3;
    font-size: 0.32rem;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #989898;
    margin-right: 0.05rem;
  }
  .btn2 {
    text-align: center;
    line-height: 0.95rem;
    height: 0.95rem;
    width: 3.68rem;
    background: #ffffff;
    border-radius: 0.1rem;
    font-size: 0.32rem;
    border: 0.03rem solid #c3c3c3;

    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #989898;
  }
  .btn3 {
    text-align: center;
    line-height: 0.95rem;
    height: 0.95rem;
    width: 7.5rem;
    background: #ff3b24;
    border-radius: 0.1rem;
    font-size: 0.32rem;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
  }
}
</style>